import {
  Box,
  Button,
  Flex,
  Icon,
  Image as ImageChakra,
  useBreakpointValue,
} from "@chakra-ui/react"
import { type PageProps } from "gatsby"

import React from "react"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react"
import { ProductCard } from "../components/product_card"
import { ProductCardSkeleton } from "../components/product_card/product_card.skeleton"
import { IReceipt, IReceipts } from "../dtos/IReceipts.dto"
import { useProductsByCategory } from "../hooks/useProduct"

import { HeadSEO } from "../components/head/"
import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter"

interface ReceitaPageProps extends PageProps {
  pageContext: {
    receipt: IReceipt
    receipts: IReceipts
  }
}

const ReceitaPage = ({
  pageContext: { receipt, receipts },
}: ReceitaPageProps) => {
  const swiperRef = React.useRef<SwiperRef>(null)

  const isMobile = useBreakpointValue({ base: true, md: false })

  const [activeCategory, setActiveCategory] = React.useState<string>("11")
  const { data: dataHighlight, isLoading: isLoadingHighlight } =
    useProductsByCategory("68")

  const configSwiper = {
    spaceBetween: 10,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      455: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3.5,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3.2,
        spaceBetween: 50,
      },
    },
  }

  const nextSlide = (
    ref: React.MutableRefObject<SwiperRef | null> | undefined,
  ) => {
    if (ref?.current && ref?.current.swiper) {
      ref?.current?.swiper?.slideNext()
    }
  }

  const prevSlide = (
    ref: React.MutableRefObject<SwiperRef | null> | undefined,
  ) => {
    if (ref?.current && ref?.current.swiper) {
      ref?.current?.swiper?.slidePrev()
    }
  }
  return (
    <body id="receita" className="receita">
      <div className="SEO d-none">
        <h1>{capitalizeFirstLetter(receipt?.title.rendered)}</h1>
        <h2>{capitalizeFirstLetter(receipt?.filtro)}</h2>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-xl-6 position-relative">
            <div className="image-follow">
              <a href="#" target="_parent">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.0817 8.94973C6.19364 9.50138 2.49865 13.6347 2.49609 18.5537V19.166C4.62047 16.6068 7.75615 15.1026 11.0817 15.0473V18.2756C11.0818 18.744 11.3495 19.1712 11.7711 19.3755C12.1926 19.5797 12.6938 19.5252 13.0615 19.235L21.0548 12.9234C21.3386 12.6997 21.5042 12.3583 21.5042 11.997C21.5042 11.6357 21.3386 11.2943 21.0548 11.0706L13.0615 4.75899C12.6938 4.46881 12.1926 4.41426 11.7711 4.61853C11.3495 4.8228 11.0818 5.24997 11.0817 5.71839V8.94973Z"
                    stroke="#025F1D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Compartilhar
              </a>
              <ImageChakra
                w="80%"
                h="80%"
                borderRadius={25}
                objectFit={"cover"}
                src={receipt?.imagem_de_capa.guid.replace("/site2018/", "/")}
                alt={receipt?.title.rendered}
              />
            </div>
          </div>
          <div className="col-xl-6 py-xl-0 py-5 receita-content">
            <span className="receita-title">{receipt?.title.rendered}</span>

            <span className="receita-subtitle my-3">
              {/* Cheesecake de amendoim e chocolate: uma sobremesa deliciosa e
              fácil de fazer. */}
            </span>
            <span className="receita-subtitle">
              {/* O cheesecake deve ser congelado por 2 horas antes de servir. É uma
              sobremesa deliciosa e fácil de fazer, perfeita para um jantar
              especial ou um lanche rápido. */}
            </span>
          </div>
        </div>

        <Flex
          w="100%"
          direction="row"
          margin="20px 0px"
          wrap="wrap"
          justify="space-between"
        >
          <span className="lembrete-video mb-2">
            Não perca o vídeo da receita!
          </span>

          <Button
            onClick={() =>
              window.open(
                receipt?.link.replace(
                  "https://youtube.com/embed/",
                  "https://youtu.be/",
                ),
                "_blank",
              )
            }
            background="#00754A"
            color="#ffffff"
            padding="20px 30px"
            borderRadius={25}
            _hover={{
              color: "#fffff",
              backgroundColor: "#00754A",
              opacity: 0.7,
            }}
            _focus={{
              color: "#ffffff",
              backgroundColor: "#00754A",
              opacity: 0.7,
            }}
          >
            <svg
              className="me-3"
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.2767 8.49737C28.9713 7.1587 28.0673 6.1027 26.918 5.74003C24.8393 5.0907 16.4993 5.0907 16.4993 5.0907C16.4993 5.0907 8.16335 5.0907 6.08068 5.74003C4.93535 6.09736 4.03135 7.15337 3.72202 8.49737C3.16602 10.9267 3.16602 16 3.16602 16C3.16602 16 3.16602 21.0734 3.72202 23.5027C4.02735 24.8414 4.93135 25.8974 6.08068 26.26C8.16335 26.9094 16.4993 26.9094 16.4993 26.9094C16.4993 26.9094 24.8393 26.9094 26.918 26.26C28.0633 25.9027 28.9673 24.8467 29.2767 23.5027C29.8327 21.0734 29.8327 16 29.8327 16C29.8327 16 29.8327 10.9267 29.2767 8.49737Z"
                stroke="#F2F4F3"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.8359 20L20.7639 16L13.8359 12V20Z"
                stroke="#F2F4F3"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Ver no Youtube
          </Button>
        </Flex>
      </div>

      <div className="container pb-5">
        <div className="row position-relative h-100">
          <div className="col-xl-9 position-relative">
            <div id="ingredientes">
              <span className="receita-title">Ingredientes</span>
              <p className="receita-ingredientes mt-5 mb-1">
                {receipt?.ingredientes ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: receipt?.ingredientes }}
                  />
                ) : (
                  <div></div>
                )}
              </p>
            </div>
            <div className="d-flex flex-column" id="mododepreparo">
              <span className="receita-title mt-5">Modo de preparo</span>
              <p className="receita-ingredientes mt-5 mb-1">
                {receipt?.modo_de_preparo ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: receipt?.modo_de_preparo,
                    }}
                  />
                ) : (
                  <div></div>
                )}
              </p>
            </div>

            {/* <div className="d-flex flex-column" id="dicas">
              <span className="receita-title mt-5 mb-5">Dicas</span>
              <ul className="receita-ingredientes">
                <li>
                  Você pode usar qualquer tipo de nozes para a massa, como
                  nozes, avelãs ou amêndoas. No entanto, se você usar nozes
                  diferentes, certifique-se de ajustar a quantidade de tâmaras
                  para obter uma massa que seja fácil de trabalhar.
                </li>
                <li>
                  {" "}
                  Se você não tiver um processador, você pode triturar as
                  castanhas e nozes em um pilão. No entanto, isso pode levar
                  mais tempo e pode ser mais difícil de obter uma textura fina.
                </li>
                <li>
                  {" "}
                  Você pode usar qualquer tipo de chocolate para a cobertura,
                  como chocolate meio amargo, chocolate branco ou chocolate
                  amargo. No entanto, se você usar chocolate com um teor de
                  cacau mais alto, o cheesecake será mais amargo.
                </li>
                <li>
                  {" "}
                  Você pode decorar o cheesecake com paçoca, castanha ou
                  amendoim triturados, raspas de chocolate ou outros
                  ingredientes de sua preferência.
                </li>
                <li>
                  O cheesecake pode ser servido gelado ou em temperatura
                  ambiente. No entanto, se você servi-lo em temperatura
                  ambiente, certifique-se de deixá-lo descansar por pelo menos
                  uma hora antes de servir, para que a massa tenha tempo de
                  firmar.
                </li>
              </ul>
            </div> */}
          </div>

          <div className="col-xl-3 position-relative">
            <div className="card-links p-4">
              <span className="link-lista-title">Links de conteúdo</span>

              <hr className="barra-link-lista" />

              <ul className="lista-link list-unstyled">
                <li>
                  <a href="#ingredientes" className="item-link-lista">
                    Ingredientes
                  </a>
                </li>

                <li>
                  <a href="#mododepreparo" className="item-link-lista">
                    Modo de preparo
                  </a>
                </li>

                <li>
                  <a href="#dicas" className="item-link-lista">
                    Dicas
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Box background="#F2F4F3" py="5rem" width="100%">
        <Box px={{ base: "1rem", md: "9rem" }}>
          <div className="row">
            <div className="col-12">
              <div className="h2 text-title-litle">Destaques</div>
              <hr
                className="my-5"
                style={{ backgroundColor: "#ffffff80", height: "1px" }}
              />
            </div>
          </div>

          {!isLoadingHighlight && dataHighlight && (
            <Box position="relative">
              <Flex
                as="button"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                opacity="0.8"
                background="#fff"
                padding="0.5rem"
                onClick={() => prevSlide(swiperRef)}
                cursor="pointer"
                position="absolute"
                left="0"
                top="50%"
                transform="translateY(-50%)"
              >
                <Icon as={IoIosArrowBack} color="#008C15" fontSize="2rem" />
              </Flex>
              <Box px={{ base: "1rem", md: "4rem" }}>
                <Swiper {...configSwiper} ref={swiperRef}>
                  {dataHighlight?.map(product => (
                    <SwiperSlide key={product.id}>
                      <Box padding="1rem">
                        <ProductCard
                          activeRibbon
                          ribbonText="Novidade"
                          product={product}
                        />
                      </Box>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
              <Flex
                as="button"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                opacity="0.8"
                background="#fff"
                padding="0.5rem"
                onClick={() => nextSlide(swiperRef)}
                cursor="pointer"
                position="absolute"
                right="0"
                top="50%"
                transform="translateY(-50%)"
              >
                <Icon as={IoIosArrowForward} color="#008C15" fontSize="2rem" />
              </Flex>
            </Box>
          )}

          {isLoadingHighlight && (
            <Flex justifyContent="space-between" alignItems="center">
              <ProductCardSkeleton quantity={isMobile ? 1 : 4} />
            </Flex>
          )}
        </Box>
      </Box>
    </body>
  )
}

export default ReceitaPage

export const Head = ({ pageContext }: ReceitaPageProps) => {
  const { receipt } = pageContext
  return HeadSEO(
    capitalizeFirstLetter(`${receipt?.title.rendered} | Verde Campo`), receipt?.meta_description, receipt?.meta_keywords
  )
}
